






































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { postSms } from '@/api/common'
import { REG } from '@/config/validate.config'
@Component
export default class Login extends Vue {
  // 表单数据
  formData: any = {
    userName: '',
    password: '',
  }
  formMobileData: any = {
    mobile: '',
    smsCode: '',
  }
  ruleForm: any = {
    timer: null, // 定时器的开关
    showloginCode: true, // 获取成功验证码后倒计时跟获取不成功后没有倒计时按钮的开关
    count: '', // 倒计时
  }
  // 表单验证规则
  formRule: any = {
    userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
  }
  formMobileRule: any = {
    mobile: [
      {
        required: true,
        message: '请输入正确的手机号码',
        trigger: 'blur',
        pattern: REG.PHONE,
      },
    ],
    smsCode: [
      {
        required: true,
        trigger: 'blur',
        message: '请输入正确的验证码',
        min: 6,
        max: 6,
      },
    ],
  }
  private loading: boolean = false
  //切换索引值
  public currentIndex: string = '1'
  @Ref('formRef')
  formRef: any
  @Ref('formMobileRef')
  formMobileRef: any
  handover(i: string) {
    if (i === '1') {
      this.formMobileRef.resetFields()
    } else {
      this.formRef.resetFields()
    }
    this.currentIndex = i
  }
  async getloginPhoneCode() {
    // 如果没有输入手机号，则不往下执行
    if (this.formMobileData.mobile == '') {
      this.$message.error('请填写手机号！')
      return false
    }
    this.formMobileRef.validateField('mobile', async (res: any) => {
      if (res) {
        return
      } else {
        const phone = this.formMobileData.mobile
        const [err] = await postSms(phone)
        if (err) {
          return this.$message.error('验证码发送失败！')
        } else {
          // 当验证码发送成功，开始60秒倒计时
          const time = 60
          if (!this.ruleForm.timer) {
            this.ruleForm.showloginCode = false
            this.ruleForm.count = time
            this.ruleForm.timer = setInterval(() => {
              if (this.ruleForm.count > 0 && this.ruleForm.count <= time) {
                this.ruleForm.count -= 1
              } else {
                this.ruleForm.showloginCode = true
                clearInterval(this.ruleForm.timer)
                this.ruleForm.timer = null
              }
            }, 1000)
          }
        }
      }
    })
  }
  async onSubmit(e: any) {
    if (this.currentIndex === '1') {
      try {
        await this.formRef.validate()
        this.loading = true
        await this.$store.dispatch('user/login', this.formData)
        this.$router.push({ path: '/' })
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$errorLog('onSubmit error', error)
      }
    } else {
      try {
        await this.formMobileRef.validate()
        this.loading = true
        await this.$store.dispatch('user/loginCode', this.formMobileData)
        this.$router.push({ path: '/' })
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$errorLog('onSubmit error', error)
      }
    }
  }
  goRegister() {
    window.location.href = process.env.VUE_APP_REGISTER_URL
  }
}
